<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="bill-payment" :class="'mr-2 btn btn-success text-light'">{{ $t('globalTrans.honour_amount') }} {{ $t('globalTrans.list') }}</router-link>
            <!-- <b-button variant="primary" @click="pdfExport" class="">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                    <!-- <fieldset class="p-2 w-100"> -->
                      <div class="table-responsive">
                        <b-table-simple class="table" style="width:100%" bordered>
                          <b-thead class="thead">
                            <b-tr>
                              <th class="text-center" style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                              <th style="width:25%" class="text-center">{{$t('globalTrans.name')}}</th>
                              <th style="width:25%" class="text-center">{{$t('globalTrans.designation')}}</th>
                              <th style="width:20%" class="text-center">{{$t('globalTrans.mobile')}}</th>
                              <th style="width:20%" class="text-center">{{$t('globalTrans.date')}}</th>
                              <th style="width:20%" class="text-center">{{$t('globalTrans.details')}}</th>
                            </b-tr>
                          </b-thead>
                          <b-tr v-for="(data, index) in trainees" :key="index">
                            <b-td class="text-center" >
                              {{ $n(index + 1) }}
                            </b-td>
                            <b-td class="text-center">
                              {{ $i18n.locale === 'bn' ? data.name_bn : data.name }}
                            </b-td>
                            <b-td class="text-center">
                              <!-- {{ ($i18n.locale==='bn') ? data.designation_name_bn : data.designation_name }} -->
                              <!-- <span v-if="data.p_designation_id && data.not_here_designation == 0">{{ getDesignation(data.p_designation_id) }}</span>
                              <span v-if="data.not_here_designation == 1 || data.p_profession_type != 1">{{ ($i18n.locale=='bn') ? data.p_designation_bn : data.p_designation_en}}</span> -->
                              {{ ($i18n.locale=='bn') ? data.p_designation_bn : data.p_designation_en}}
                            </b-td>
                            <b-td class="text-center">
                              {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.mobile, { useGrouping: false }) }}
                            </b-td>
                            <b-td class="text-center">
                              {{ data.payment_date | dateFormat }}
                            </b-td>
                            <b-td class="text-center">
                              <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.circular_memo_no, data.batch_no, data.mobile, data.payment_date, data.name, data.name_bn, data.p_designation_en, data.p_designation_bn, data.p_office_name, data.p_office_name_bn)"><i class="fas fa-eye"></i></a>
                            </b-td>
                          </b-tr>
                        </b-table-simple>
                      </div>
                    <!-- </fieldset> -->
                  </div>
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{trainees}}</pre> -->
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('elearning_tim.individual_honorarium_title')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-button variant="primary" @click="pdfExport" class="btn-success water-test-pdf-button" style="margin-right: 100px;">
        {{  $t('globalTrans.export_pdf') }}
      </b-button>
      <b-button variant="primary" @click="pdfExportDownload" class="ml-4 btn-success water-test-pdf-button">
        {{  $t('globalTrans.download') }}
      </b-button>
      <b-button variant="primary" @click="wordExportDownload" class="ml-4 btn-success water-test-pdf-button2">
        {{  $t('globalTrans.word') }}
      </b-button>
      <Details :item="item" :key="id" ref="details"></Details>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
// import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
// import ExportPdf from './export_pdf_details'
import { billPaymentHonorarumDetails } from '../../api/routes'
import Details from './IndividualDetails'

export default {
    components: {
      Details
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      item: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  methods: {
    getDesignation (id) {
      const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return designation !== undefined ? designation.text_bn : ''
      } else {
        return designation !== undefined ? designation.text_en : ''
      }
    },
    details (data, data1, data2, data3, data4, data5, data6, data7, data8, data9) {
      this.item.circular_memo_no = data
      this.item.batch_no = data1
      this.item.mobile = data2
      this.item.payment_date = data3
      this.item.name = data4
      this.item.name_bn = data5
      this.item.designation = data6
      this.item.designation_bn = data7
      this.item.office = data8
      this.item.office_bn = data9
    },
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo,
            batch_no: this.$route.query.batch_no
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
        } else {
            this.honorariumSheet = result.data[0]
            this.getCustomDataZeroList(result.data[0])
            const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainer')
            this.trainees = this.getCustomDataList(trainees)
            this.materials = result.matherial
        }
        this.load = false
      }
    },
    // pdfExport () {
    //   const reportTitle = this.$t('globalTrans.honour_amount') + ' ' + this.$t('globalTrans.details')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.trainees)
    // },
    pdfExport () {
      this.$refs.details.pdfExport()
    },
    pdfExportDownload () {
      this.$refs.details.pdfExportDownload()
    },
    wordExportDownload () {
      this.$refs.details.wordExportDownload()
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const designationObj = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
          const designationData = {}
          if (typeof designationObj !== 'undefined') {
            designationData.designation_name = designationObj.text_en
            designationData.designation_name_bn = designationObj.text_bn
          } else {
            designationData.designation_name = ''
            designationData.designation_name_bn = ''
          }
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.personal.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, designationData, customItem, desigData)
        })
        return listData
      },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
          if (typeof batchObj !== 'undefined') {
            this.honorariumSheet.batch_name = batchObj.text_en
            this.honorariumSheet.batch_name_bn = batchObj.text_bn
          } else {
            this.honorariumSheet.batch_name = ''
            this.honorariumSheet.batch_name_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  .water-test-pdf-button2 {
      position: absolute !important;
      right: 295px !important;
      z-index: 999 !important;
      top: -39px !important;
      background: white !important;
      color: #66cc99 !important;
  }
</style>
